import { Pipe, PipeTransform } from '@angular/core';
import { IBedType } from '../master-data';
import { MasterDataService } from '../../services/master-data.service';


@Pipe({
  name: 'bedType',
  standalone: true 
})
export class BedTypePipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private bedTypes: IBedType[] = [{}];


  transform(bedTypeId: number | undefined): string {

    if(!bedTypeId){
      return "";
    }
      /* Check if the bedType array has empty object */
    if (this.bedTypes.length == 1) {
      this.masterDataService.bedType$.subscribe(bedTypes => {
        this.bedTypes = bedTypes;
      })
    }
    const bedType = this.bedTypes.filter(bedType => bedType.bed_type_id == bedTypeId )
    return bedType[0].bed_type || '';
  }

}
