import { Pipe, PipeTransform } from '@angular/core';
import { IBedType, ICategory } from '../master-data';
import { MasterDataService } from '../../services/master-data.service';


@Pipe({
  name: 'category',
  standalone: true 
})
export class CategoryPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private categories: ICategory[] = [{}];


  transform(categoryId: number | undefined): string {

    if(!categoryId){
      return "";
    }
      /* Check if the bedType array has empty object */
    if (this.categories.length == 1) {
      this.masterDataService.category$.subscribe(categories => {
        this.categories = categories;
      })
    }
    const category = this.categories.filter(category => category.category_id == categoryId )
    return category[0].category || '';
  }

}
