import { CommonModule } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription, finalize } from 'rxjs';
import { IBedType, ICategory } from '../../shared/master-data/master-data';
import { IScreensize } from '../../shared/screen-size';
import { MasterDataService } from '../../shared/services/master-data.service';
import { NotificationService } from '../../shared/services/notification.service';
import { PropertyService } from '../../shared/services/property.service';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { PhotoListComponent } from './photo-list/photo-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserStorageService } from '../../shared/services/storage.service';
// import { IPropertyPhoto } from '../property';
@Component({
  selector: 'app-photo',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    MatProgressBarModule,
    TranslateModule,
    PhotoListComponent,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    MatRadioModule],
  templateUrl: './photo.component.html',
  styleUrl: './photo.component.scss'
})
export class PhotoComponent {
  public property_room!: string;
  public propertyName!: string | null;
  // @Input() getPropertyPhoto!:IPropertyPhoto[] ;
  // private view_property_id!: number;
  public bed_types!: IBedType[];
  public categories!: ICategory[];
  public bed_type_id!: number;
  public category_id!: number;
  // User id of whose profile is being viewed
  private property_id!: number;
  public screenInfo!: IScreensize;
  requiredFileType: string = 'jpg|webp';
  fileName = '';
  uploadProgress!: number | null;
  uploadSub!: Subscription | null;
  refreshPhotoList: boolean = false;

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private propertyService: PropertyService,
    private notificationService: NotificationService,
    private screensizeDetectService: ScreensizeDetectService,
    private router: Router,
    private browserStorageService: BrowserStorageService,
    private readonly masterDataService: MasterDataService
  ) {
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
    this.getMasterData();
    if (this.property_room == "property") {
      //this.validatePhoto();
    }
  }

  ngOnInit(): void {

    this.propertyName = this.browserStorageService.get('propertyName');
    /* Get the user id from route params */
    const property_id = this.route.snapshot.paramMap.get('propertyId');
    if (property_id) {
      this.property_id = +property_id;
    }

  }

  getMasterData() {
    this.masterDataService.bedType$.subscribe(bedTypes => {
      this.bed_types = bedTypes;
    })
    this.masterDataService.category$.subscribe(categories => {
      this.categories = categories;
    })
  }

  onFileSelected(event: Event) {

    this.refreshPhotoList = false;
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file: File = input.files[0];

    if (file) {

      this.fileName = file.name;
      const formData = new FormData();
      formData.append("image", file);
      // Append user id only if the id is not null. If null it is owner
      if (this.property_id !== null) {

        formData.append("property_id", String(this.property_id));
        if (this.property_room == "room") {
          formData.append("bed_type_id", String(this.bed_type_id));
          formData.append("category_id", String(this.category_id));
        }
        //console.log(this.category_id);
      }

      const upload = this.propertyService.updatePropertyPhoto(formData, '').pipe(finalize(() => this.reset()))
      console.log(upload)
      this.uploadSub = upload.subscribe(event => {

        console.log(event);
        if (event.type == HttpEventType.UploadProgress) {

          if (event.total) {

            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            console.log(this.uploadProgress)
            /* Display the success message after reaching 100% */
            if (this.uploadProgress === 100) {

              /* Get translation text */
              let successMsg = '';
              this.translate.get('PHOTO_UPLOAD_SUCCESS_MSG').subscribe((tranlatedText: string) => {
                successMsg = tranlatedText;
              });
              this.notificationService.displayNotification(successMsg);
              //const url = this.router.url;


              //this.router.navigate(['user/details/photo-manage']);
            }
            // this.getPropertyPhotos(this.view_property_id);


          }

        } else {

          console.log(event);

          if (event.status === 200) {
            this.refreshPhotoList = true;
          }
          this.uploadSub?.unsubscribe();
          // refresh here
          /* reload the page to refresh the photo list */

        }

      })
    }

  }

  // getPropertyPhotos(propertyId: number) {
  //   this.getPhotoSubscription = this.propertyService.getPropertyPhotos(propertyId).subscribe(data => {
  //     this.photos = data.photo_details;
  //   })
  // }

  cancelUpload() {
    this.uploadSub?.unsubscribe();
    this.reset();

    /* Get translation text */
    let successMsg = '';
    this.translate.get('PHOTO_UPLOAD_CANCEL_MSG').subscribe((tranlatedText: string) => {
      successMsg = tranlatedText;
    });

    /* Display the notification */
    this.notificationService.displayNotification(successMsg);
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  validatePhoto(): ValidatorFn {
    alert("hi")
    return (control: AbstractControl): ValidationErrors | null => {
      const photoSelectionControl = control.get('property_room');
      const bed_type_id = control.get('bed_type_id');
      const category_id = control.get('category_id');
      if (photoSelectionControl?.value == "property") {
        bed_type_id?.setErrors(null);
        category_id?.setErrors(null);
        return null;
      } else {
        return { commisionControlValidators: true }
      }
    }
  }

}