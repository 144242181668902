<div class="jk-form-container">
    <mat-toolbar class="jk-form-field-container">

        <div>
            <span>Delete Floor</span>
        </div>
        <div fxFlex></div>
    </mat-toolbar>
    <form class="jk-form-field-container" [formGroup]="deleteRoomFormGroup">
        <mat-dialog-content>
            <mat-form-field>
                <mat-label>Room Number</mat-label>
                <mat-select formControlName="room_id">
                    @for (room of rooms; track room.room_id) {
                        <mat-option [value]="room.room_id">{{room.room_number }}</mat-option>
                        }
                </mat-select>

            </mat-form-field>

        </mat-dialog-content>
        <mat-dialog-actions>
            <span fxFlex></span>
            <button mat-raised-button color="primary" (click)="cancel()" mat-dialog-close>
                {{ 'DIALOG_CANCEL_BTN'| translate }}
            </button>

            <button mat-raised-button color="warn" type="submit" (click)="submit()" mat-dialog-close>
                {{ 'DIALOG_CONFIRM_BTN'| translate }}
            </button>

        </mat-dialog-actions>
    </form>
</div>