import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { IPropertyRoom, IPropertyRoomPrice } from '../../../property/property';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import moment from 'moment';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { IBedType, ICategory, IWashroomType } from '../../../../shared/master-data/master-data';
import { MasterDataService } from '../../../../shared/services/master-data.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { PropertyService } from '../../../../shared/services/property.service';
import { SpinnerComponent } from '../../../../shared/spinner/spinner.component';
import { PhotoComponent } from '../../../photo/photo.component';
import { IPropertyRoom, IPropertyRoomPrice } from '../../../property';
import { RouterLink, RouterOutlet, RouterModule } from '@angular/router';
import { BrowserStorageService } from '../../../../shared/services/storage.service';

//const moment = moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-add-room',
  standalone: true,
  imports: [CommonModule,
    MatInputModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    MatSelectModule,
    MatSlideToggleModule,
    PhotoComponent,
    SpinnerComponent,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    ConfirmDialogComponent,
    HttpClientModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule],
  templateUrl: './add-room.component.html',
  styleUrl: './add-room.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AddRoomComponent {
  public propertyName!: string | null;
  public showRoomDetails: boolean = true;
  public room_id!: number;
  public floor_id!: number;
  public property_id!: number;
  public propertyRooms!: IPropertyRoom;
  public total_capacity: number = 2
  public propertyRoomsPrice!: IPropertyRoomPrice[];
  public bed_types!: IBedType[];
  public categories!: ICategory[];
  public washroom_types!: IWashroomType[];
  public roomsFormGroup!: FormGroup;
  private confirmAction = false;
  public noOfRowsForPrice: number = 0
  DEFAULT_NO_OF_PRICE_ROW: number = 3;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private propertyService: PropertyService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly masterDataService: MasterDataService,
    private notificationService: NotificationService,
    private browserStorageService: BrowserStorageService,
    private router: Router) {
    const room_id = this.route.snapshot.paramMap.get('roomId');
    const floor_id = this.route.snapshot.paramMap.get('floorId');
    const property_id = this.route.snapshot.paramMap.get('propertyId');




    // Check if property id exist in the url
    if (floor_id && property_id) {

      this.property_id = +property_id; // convert the property id from string to number
      this.floor_id = +floor_id;

    } else {

      // Todo: Dispaly error message
    }

    this.getMasterData();
    this.setFormFields();
    if (room_id) {
      this.room_id = +room_id;
      // Edit
      this.getRoomDetails();


    } else {
      this.getRooms();
      // Add Room
      this.setFormFields();
    }
  }
  public getPriceFormArray() {
    return this.roomsFormGroup.controls["room_prices"] as FormArray;

  }
  ngOnInit() {


    this.showRoomDetails = false;
    this.propertyName = this.browserStorageService.get('propertyName');
   

  }
  getRooms() {
    console.log(this.property_id,this.floor_id)
    this.propertyService.getRooms(this.property_id, this.floor_id).subscribe(result => {
      this.propertyRooms = result.data;
      console.log(this.propertyRooms)
      this.setPriceForm()
    });
  }

  getRoomDetails() {

    this.propertyService.getRoomDetails(this.room_id).subscribe(result => {
      this.propertyRooms = result.data;
      this.setFormFields();
      this.setPriceForm();
    });
  }
  getMasterData() {
    this.masterDataService.bedType$.subscribe(bedTypes => {
      this.bed_types = bedTypes;
    })
    this.masterDataService.washroomType$.subscribe(washroomTypes => {
      this.washroom_types = washroomTypes;
    })

    this.masterDataService.category$.subscribe(categories => {
      this.categories = categories;
    })
  }
  setFormFields() {
    this.roomsFormGroup = this.fb.group({
   
      room_number: [(this.propertyRooms && this.propertyRooms.room_number !== null) ? this.propertyRooms.room_number : null, [Validators.required,]],
      bed_type_id: [(this.propertyRooms && this.propertyRooms.bed_type_id !== null) ? this.propertyRooms.bed_type_id : null, Validators.required],
      category_id: [(this.propertyRooms && this.propertyRooms.category_id !== null) ? this.propertyRooms.category_id : 1, Validators.required],
      no_of_occupancy: [(this.propertyRooms && this.propertyRooms.no_of_occupancy !== null) ? this.propertyRooms.no_of_occupancy : null, Validators.required],
      no_of_extra_bed: [(this.propertyRooms && this.propertyRooms.no_of_extra_bed !== null) ? this.propertyRooms.no_of_extra_bed : null, Validators.required],
      price_for_extra_bed: [(this.propertyRooms && this.propertyRooms.price_for_extra_bed !== null) ? this.propertyRooms.price_for_extra_bed : null, Validators.required],
      washroom_type_id: [(this.propertyRooms && this.propertyRooms.washroom_type_id !== null) ? this.propertyRooms.washroom_type_id : null, Validators.required],
      water_heater: [(this.propertyRooms && this.propertyRooms.water_heater !== null) ? this.propertyRooms.water_heater : false],
      ac: [(this.propertyRooms && this.propertyRooms.ac !== null) ? this.propertyRooms.ac : false],
      tv: [(this.propertyRooms && this.propertyRooms.tv !== null) ? this.propertyRooms.tv : false],
      sun: [(this.propertyRooms && this.propertyRooms.sun !== null) ? this.propertyRooms.sun : true],
      mon: [(this.propertyRooms && this.propertyRooms.mon !== null) ? this.propertyRooms.mon : true],
      tue: [(this.propertyRooms && this.propertyRooms.tue !== null) ? this.propertyRooms.tue : true],
      wed: [(this.propertyRooms && this.propertyRooms.wed !== null) ? this.propertyRooms.wed : true],
      thu: [(this.propertyRooms && this.propertyRooms.thu !== null) ? this.propertyRooms.thu : true],
      fri: [(this.propertyRooms && this.propertyRooms.fri !== null) ? this.propertyRooms.fri : true],
      sat: [(this.propertyRooms && this.propertyRooms.sat !== null) ? this.propertyRooms.sat : true],
      select_all: [true],

      room_prices: this.fb.array([]),
      //room_prices
    });
  }

  setPriceForm() {

    let priceFormArrayForRoom = this.getPriceFormArray(); // i is the room index
    this.noOfRowsForPrice = (this.propertyRooms.room_prices?.length > this.DEFAULT_NO_OF_PRICE_ROW) ? priceFormArrayForRoom.length : this.DEFAULT_NO_OF_PRICE_ROW;
    this.propertyRoomsPrice = this.propertyRooms?.room_prices;
    // Append price form array for each room
    for (let i = 0; i < this.noOfRowsForPrice; i++) {
      this.showRoomDetails = true;
      this.addPriceRow(i);

    }


  }
  checkAll() {
    /**
      * If the select all formcontrolname is true the if block is executed and enters else block only if select all is false.
      */
    if (this.roomsFormGroup.controls['select_all'].value) {
      this.roomsFormGroup.controls['sun'].setValue(true);
      this.roomsFormGroup.controls['mon'].setValue(true);
      this.roomsFormGroup.controls['tue'].setValue(true);
      this.roomsFormGroup.controls['wed'].setValue(true);
      this.roomsFormGroup.controls['thu'].setValue(true);
      this.roomsFormGroup.controls['fri'].setValue(true);
      this.roomsFormGroup.controls['sat'].setValue(true);
    } else {

      this.roomsFormGroup.controls['sun'].setValue(false);
      this.roomsFormGroup.controls['mon'].setValue(false);
      this.roomsFormGroup.controls['tue'].setValue(false);
      this.roomsFormGroup.controls['wed'].setValue(false);
      this.roomsFormGroup.controls['thu'].setValue(false);
      this.roomsFormGroup.controls['fri'].setValue(false);
      this.roomsFormGroup.controls['sat'].setValue(false);
    }
  }

  /* Add price form array for each room */
  public addPriceRow(i: number) {

    const priceFormArrayForRoom = this.getPriceFormArray();
    const addPriceFormGroup = this.createPriceRowGroup(i);

    addPriceFormGroup.setValidators(this.commisionControlValidators());
    priceFormArrayForRoom.push(addPriceFormGroup);

  }
  private createPriceRowGroup(i: number): FormGroup {
    // For First row, From Date should be always 1st Jan of current year. If we add propery in 2024, then it is 01-01-2024. If we add in 2025, 01-01-2025. All the dates should be for specific year. 
    // In the backend, we will ignore the year and take only day and month while fetching the data. 
    // The reason for added year in the date field is that there is no option in the calender to select only day and month.


    let currentYear = moment().format('YYYY');
    let defaultFromDate;
    let defaultToDate;
    console.log(currentYear);
    if (i === 0) {

      if (this.propertyRoomsPrice && this.propertyRoomsPrice.length > 0) {

        defaultFromDate = moment(this.propertyRoomsPrice[i].from_date);
        console.log("d", defaultFromDate);
        defaultToDate = moment(this.propertyRoomsPrice[i].to_date);

      } else {
        // Get the first day of the year
        defaultFromDate = moment().startOf('year');
        defaultFromDate = moment(defaultFromDate, "YYYY-MM-DD").format('YYYY-MM-DD');


        defaultToDate = moment(defaultFromDate).add(5, 'months').subtract(1, 'day');
        defaultToDate = moment(defaultToDate, "YYYY-MM-DD").format('YYYY-MM-DD');

      }

    } else {

      // Edit: Use the same value which is coming from backend
      if (this.propertyRoomsPrice && this.propertyRoomsPrice.length > 0) {
        defaultFromDate = moment(this.propertyRoomsPrice[i].from_date);

        defaultToDate = moment(this.propertyRoomsPrice[i].to_date);

      } else {
        // Add: Read the from date value should be "To Date" of previous row plus 1  
        const priceRowFormGroup = this.getPriceFormArray().controls[i - 1] as FormGroup;
        const previousRowToDate = priceRowFormGroup.get('to_date')?.value;
        defaultFromDate = moment(previousRowToDate).add(1, 'day');

        // if this is the last row, set the end date as 31/12/2024
        if (i === (this.noOfRowsForPrice - 1)) {
          defaultToDate = moment().endOf('year');
        } else {
          defaultToDate = moment(defaultFromDate).add(3, 'months').subtract(1, 'day');
        }
      }

    }
    return new FormGroup({
      // ToDo: name has to be changed as per the table
      price: new FormControl(this.propertyRoomsPrice && this.propertyRoomsPrice[i] ? (this.propertyRoomsPrice[i]).price / 100 : '', [Validators.required]),
      commission_percent: new FormControl(this.propertyRoomsPrice && this.propertyRoomsPrice[i] ? (this.propertyRoomsPrice[i]).commission_percent : ''),
      commission_rupees: new FormControl(this.propertyRoomsPrice && this.propertyRoomsPrice[i] ? (this.propertyRoomsPrice[i]).commission_rupees / 100 : ''),
      from_date: new FormControl(moment(defaultFromDate, "YYYY-MM-DD").format('YYYY-MM-DD'), [Validators.required]),
      to_date: new FormControl(moment(defaultToDate, "YYYY-MM-DD").format('YYYY-MM-DD'), [Validators.required]),
   
      discounted_price: new FormControl(this.propertyRoomsPrice && (this.propertyRoomsPrice[i]) ? (this.propertyRoomsPrice[i]).discounted_price / 100 : '',)
    
    })
  }


  removePrice1(i: number) {

    if (this.getPriceFormArray().length > 1) {
      {
        this.showContactsDeleteConfirmationDialog(this.getPriceFormArray(), i);
      }

    }
  }

  public showContactsDeleteConfirmationDialog(priceFormArray: FormArray, i: number) {

    let title = '';
    let deleteConfirmationMessage = '';
    this.translate.get('CONFIRM_DETELE_HEADER').subscribe((tranlatedText: string) => {
      title = tranlatedText;
    });

    this.translate.get('CONFIRM_CONTACTS_DELETE_MSG').subscribe((tranlatedText: string) => {
      deleteConfirmationMessage = tranlatedText;
    });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        title,
        message: deleteConfirmationMessage
      }
    });
    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
      if (this.confirmAction) {
        priceFormArray.removeAt(i);
        //Mark the form as dirty so that save button triggers request upon clicking
        this.roomsFormGroup.markAsDirty();
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogSub.unsubscribe();
    });

  }


  onSubmit() {

    const params = {
      property_id: this.property_id,
      floor_id: this.floor_id,
    }
    console.log(this.getPriceFormArray().value);
    //price amount changed to paise
    const priceArray: IPropertyRoomPrice[] = this.getPriceFormArray().value;
    // console.log(priceArray)
    const PriceDetailsArray: IPropertyRoomPrice[] = priceArray.map(value => {
      value.discounted_price = value.discounted_price * 100;
      value.price = value.price * 100;
      value.commission_rupees = value.commission_rupees * 100;
      return value;
    });
    console.log(PriceDetailsArray)
    const roomDetails = {
      ...this.roomsFormGroup.value, ...params,
    } 
    roomDetails.room_prices.PriceDetailsArray

    if (this.propertyRooms && this.propertyRooms.room_id) {
      // Update room
      this.propertyService.updateRoom(this.propertyRooms.room_id, roomDetails).subscribe(response => {

        /* Get translation text */
        let successMsg = '';
        this.translate.get('ROOM_DETAILS_SAVE_SUCCESS_MSG').subscribe((tranlatedText: string) => {
          successMsg = tranlatedText;

          this.notificationService.displayNotification(successMsg);
          console.log(this.property_id, this.floor_id)

          this.router.navigate(['/admin/property/manage-rooms/', this.property_id, this.floor_id]);

        });
      });
    } else {

      const params = {
        property_id: this.property_id,
        floor_id: this.floor_id,
      }


      console.log(this.getPriceFormArray().value);
      const roomDetails = {
        ...this.roomsFormGroup.value, ...params,
      }
      roomDetails.room_prices.PriceDetailsArray

      // Add room
      this.propertyService.saveRoom(roomDetails).subscribe(response => {


        /* Get translation text */
        let successMsg = '';
        this.translate.get('ROOM_DETAILS_SAVE_SUCCESS_MSG').subscribe((tranlatedText: string) => {
          successMsg = tranlatedText;

        });

        /* Display the notification */
        this.notificationService.displayNotification(successMsg);
        this.router.navigate(['/admin/property/manage-rooms/', this.property_id, this.floor_id]);
      });
    }
  }

  commisionControlValidators(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const priceFormGroup = group as FormGroup;
      const commissionPcControl = priceFormGroup.get('commission_percent');
      const commissionRsControl = priceFormGroup.get('commission_rupees')
      if (commissionPcControl?.value && commissionRsControl?.value) {
        commissionPcControl?.setErrors({ notEquivalent: true })
        commissionRsControl?.setErrors({ notEquivalent: true })
        return { commisionControlValidators: true }
      } else if (commissionPcControl?.value || commissionRsControl?.value) {
        commissionPcControl?.setErrors(null);
        commissionRsControl?.setErrors(null);
        return null;
      } else {
        commissionPcControl?.setErrors({ notEquivalent: true })
        commissionRsControl?.setErrors({ notEquivalent: true })
        return { commisionControlValidators: true }
      }
    }
  }

  /**
   * This will read the value of to date from the previous row and add plus 1
   */
  getFromDateForNextRow(currentRowIndex: number) {
    const priceRowFormGroup = this.getPriceFormArray().controls[currentRowIndex] as FormGroup;
    const currentRowToDate = priceRowFormGroup.get('to_date')?.value;
    return moment(currentRowToDate).add(1, 'day');

  }
  resetDateForNextRow(e: MatDatepickerInputEvent<moment.Moment>, currentRowIndex: number) {
    // We are not using the date received at the moment as the below funtion reads the data from the form.
    const defaultFromDate = this.getFromDateForNextRow(currentRowIndex);
    // Get the from_date obj of next row
    const priceRowFormGroup = this.getPriceFormArray().controls[currentRowIndex + 1] as FormGroup;
    priceRowFormGroup.get('from_date')?.setValue(defaultFromDate);

  }


} 