import { Pipe, PipeTransform } from '@angular/core';
import { IFloors } from '../master-data';
import { MasterDataService } from '../../services/master-data.service';


@Pipe({
  name: 'floorNames',
  standalone: true
})
export class FloorNamesPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private floorNames: IFloors[] = [];


  transform(floorNamesId: number | undefined): string {

    if (!floorNamesId) {
      return "";
    }
    /* Check if the floorNames array has empty object */
    if (this.floorNames.length == 0) {
      this.masterDataService.floorNames$.subscribe(floorNames => {
        this.floorNames = floorNames;
      })
    }
    const floorNames = this.floorNames.filter(floorNames => floorNames.floor_name_id == floorNamesId)
    return floorNames[0].floor_name || '';
  }

}
