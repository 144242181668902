import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { IDeleteFloorDialogData, IDeleteRoomDialogData, IRooms } from '../../property';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MasterDataService } from '../../../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PropertyService } from '../../../shared/services/property.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SafeHtmlPipe } from '../../../shared/safe-html.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-delete-room',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatToolbarModule,
    MatDialogActions,
    MatDialogContent,
    SafeHtmlPipe,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogClose,],
  templateUrl: './delete-room.component.html',
  styleUrl: './delete-room.component.scss'
})
export class DeleteRoomComponent {
  // @Input() element: string = 'button';
  public deleteRoomFormGroup!: FormGroup;
  @Output() confirmAction = new EventEmitter<boolean>();
  public rooms!: IRooms[];
  public floor_id!: number;
  public propertyId!: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDeleteRoomDialogData,
    private fb: FormBuilder, private propertyService: PropertyService,
    private readonly masterDataService: MasterDataService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService) {
    const propertyId = this.route.snapshot.paramMap.get('propertyId');
    console.log(propertyId)
    if (propertyId) {
      this.propertyId = +propertyId;
    }
    const floor_id = this.route.snapshot.paramMap.get('floor_id');
    console.log(floor_id)
    if (floor_id) {
      this.floor_id = +floor_id;
    }
    this.setFormFields();
    // this.getFloorDetails();
    // this.getMasterData()
    console.log(this.data)
  }

  ngOnInit() {
    //this.getFloorDetails();
   this.rooms = this.data.roomDetails
  }
  setFormFields() {
    this.deleteRoomFormGroup = this.fb.group({
      room_id: [Number],
    });

  }


  submit(): void {
    //   const floor_id=this.data.floorDetails.values;
    //   console.log(floor_id)
    this.confirmAction.emit(true);
    console.log(this.deleteRoomFormGroup.value)
      if (this.deleteRoomFormGroup.valid) {

        let params = {

          property_id: this.data.propertyId,
          room_id: this.deleteRoomFormGroup.get('room_id')?.value,
          // floor_name_id: this.deleteFloorFormGroup.get('floor_name_id')!.value,
        }

        this.propertyService.deleteRoom(params.room_id).subscribe(result => {
          // this.existingFloors= result.data;
          // console.log("del",this.existingFloors)

        });
      }


  }
  cancel(): void {
    this.confirmAction.emit(false);
  }





}
