<!-- FloorId is required as we are going to show or add rooms for specific floor-->
@if(floorId){
<div class="jk-floor-container">
    <div class="jk-property-name">
        <h1>{{propertyName}}</h1>
    </div>
    <div class="jk-floor-no">
        <h2> {{floor_name_id | floorNames}} Floor </h2><!--Todo: Floor Number to be displayed instead of FloorId -->

    </div>
    <h1>Rooms</h1>

    <div class="jk-room-details">


        <div class="jk-room-details-btn">


            <!-- @for (room of rooms; track rooms) {

            <a [routerLink]="['/admin/property/edit-room/',propertyId,floorId,room.room_id ]">
                <button mat-raised-button color="primary">{{room.room_number}}</button>
            </a>

            } -->


            <a [routerLink]="['/admin/property/add-rooms/', propertyId, floorId]">
                <button mat-raised-button color="primary">Add
                    Room</button>
            </a>

            @if(rooms?.length){
            <a><button mat-raised-button color="warn" (click)="deleteRoomDialogBox()">Delete
                    Room</button></a>
            }

            <div class="jk-floor-refresh-btn">
                <button><mat-icon aria-hidden="false" (click)="getRoomDetails()">refresh</mat-icon></button>
            </div>

        </div>




    </div>
    @if( rooms.length === 0 ){
    <div class="jk-room-msg">No rooms available.</div>
    }

</div>

@if( rooms.length > 0 ){
<div class="jk-form-field-container">

    <div class="jk-table-container">
        <div class="jk-table-header">
            <div class="jk-table-content">Room Number</div>
            <div class="jk-table-content">Room Id</div>
        </div>
        <div class="jk-room-content">
            @for (room of rooms; track rooms) {
            <div class="jk-room-details">
                <div class="jk-room-name">
                    <a [routerLink]="['/admin/property/edit-room/', propertyId,floorId,room.room_id]">
                        <span>{{room.room_number}}</span></a>
                </div>

                <div class="jk-room-no">
                    <a [routerLink]="['/admin/property/edit-room/', propertyId,floorId,room.room_id]">
                        <span>{{room.room_id}}</span></a>
                </div>
            </div>
            }
        </div>

    </div>


</div>
}

}@else {
Floor is not selected property.
}
<!-- <app-add-room></app-add-room> -->