import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
// import { environment } from '../../..//environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { BedTypePipe } from '../../../shared/master-data/pipes/bed-types.pipe';
import { CategoryPipe } from '../../../shared/master-data/pipes/categories.pipe';
import { NotificationService } from '../../../shared/services/notification.service';
import { PropertyService } from '../../../shared/services/property.service';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { IPropertyPhoto } from '../../property';

@Component({
  selector: 'app-photo-list',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    CategoryPipe,
    BedTypePipe,
    SpinnerComponent,
    MatButtonModule],
  templateUrl: './photo-list.component.html',
  styleUrl: './photo-list.component.scss'
})

export class PhotoListComponent implements OnInit, OnDestroy {
  @Input() refreshPhotoList!: boolean;
  public thumbnailUrl = environment.imageUrl + 'thumbnail/';

  // User id of whose profile is being viewed
  private view_property_id!: number;
  photos: IPropertyPhoto[] = [];
  primary_photo_id!: number;
  private deletePhotoSubscription!: Subscription;
  private getPhotoSubscription!: Subscription;
  private getPrimaryPhotoSubscription!: Subscription;
  private confirmAction = false; // Recieves value from the its child component (ConfirmDialogComponent)
  public showSpinner: boolean = false;

  constructor(
    private propertyService: PropertyService,
    private readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly translate: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    /* Get the user id from route params */
    const view_property_id = this.route.snapshot.paramMap.get('propertyId');
    if (view_property_id) {
      this.view_property_id = +view_property_id
    }
    console.log(this.view_property_id)
    // // Convert the propertyId string into int
    // this.view_property_id = (view_property_id !== null) ? +view_property_id : view_property_id;

    this.getPropertyPhotos(this.view_property_id);
    /*
        * Get all the photos of the given user or owner along with the primary photo id.
        * Fork Join combine all the observals and wait untit all the observable returns the value
    */

    // forkJoin([
    //   /* Get primary photo id for the given user or the owner */
    //   this.propertyService.getUserPrimaryPhotoId(this.view_property_id),

    //   /* Get all the photos for the given user or the owner */
    //   this.propertyService.getUserPhotos(this.view_property_id)

    // ]).subscribe(([primary_photo, data]) => {
    //   this.primary_photo_id = primary_photo.primary_photo_id;
    //   this.photos = data.photo_details;

    // });

  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['refreshPhotoList']) {
      if (changes['refreshPhotoList'].currentValue) {
        this.getPropertyPhotos(this.view_property_id);
      };


    }
  }




  getPropertyPhotos(propertyId: number) {
    this.showSpinner = true;
    this.getPhotoSubscription = this.propertyService.getPropertyPhotos(propertyId).subscribe(data => {
      this.photos = data.photo_details;
      this.showSpinner = false;
    })

  }
  // getUserPrimaryPhoto(propertyId: number) {
  //   this.getPrimaryPhotoSubscription = this.propertyService.getUserPrimaryPhotoId(propertyId).subscribe(data => {
  //     this.primary_photo_id = data;
  //   })
  // }

  setAsPrimaryPhoto(photoId: number) {
    let params = {
      primary_photo_id: photoId
    }
    if (this.view_property_id !== null) {

      /* Append the user when back end user make changes for a user */
      params = { ...params, ...{ property_id: this.view_property_id } };
    }

    this.propertyService.setPropertyPrimaryPhoto(params).subscribe(result => {

      /* Get translation text */
      let successMsg = '';
      this.translate.get('PRIMARY_PHOTO_SET_SUCCESS_MSG').subscribe((tranlatedText: string) => {
        successMsg = tranlatedText;
      });


      //this.primary_photo_id = photoId;
      this.notificationService.displayNotification(successMsg);
      this.setPrimaryIdInThePhotoObject(photoId);
    });

  }

  /** This is to avoid sending a new request to backend to display the newly selected photo as primary photo.   */
  setPrimaryIdInThePhotoObject(photoId: number) {

    this.photos = this.photos.map(photo => {
      if (photo.photo_id === photoId) {
        photo.primary_photo = true;
      } else {
        photo.primary_photo = false;
      }
      return photo;
    })

  }

  deletePhoto(photoId: number) {
    this.deletePhotoSubscription = this.propertyService.deletePhoto(photoId).subscribe(data => {

      /* Get translation text */
      let successMsg = '';
      this.translate.get('PHOTO_DELETE_SET_SUCCESS_MSG').subscribe((tranlatedText: string) => {
        successMsg = tranlatedText;
      });

      /* Refersh the list */
      this.getPropertyPhotos(this.view_property_id);
      this.notificationService.displayNotification(successMsg);
    });
  }

  getUserDeleteConfirmation(photoId: number) {
    /* Get translation text */
    let confirmMsg = '';
    this.translate.get('PHOTO_DELETE_CONFIRM_MSG').subscribe((tranlatedText: string) => {
      confirmMsg = tranlatedText;
    });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '520px',
      data: {
        title: 'Confim',
        message: confirmMsg
      }
    });

    // Subscribe to the event Emitted by its child (ConfirmDialogComponent)
    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogSub.unsubscribe();
      if (this.confirmAction) {
        this.deletePhoto(photoId);
      }
    });
  }

  getUserPrimaryPhotoConfirmation(photoId: number) {
    /* Get translation text */
    let confirmMsg = '';
    this.translate.get('PRIMARY_PHOTO_SET_CONFIRM_MSG').subscribe((tranlatedText: string) => {
      confirmMsg = tranlatedText;
    });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '520px',
      data: {
        title: 'Confim',
        message: confirmMsg
      }
    });

    // Subscribe to the event Emitted by its child (ConfirmDialogComponent)
    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
    });

    dialogRef.afterClosed().subscribe(result => {
      dialogSub.unsubscribe();
      if (this.confirmAction) {
        this.setAsPrimaryPhoto(photoId);
      }
    });
  }
  ngOnDestroy() {
    this.getPhotoSubscription?.unsubscribe()
    this.getPrimaryPhotoSubscription?.unsubscribe()
    this.deletePhotoSubscription?.unsubscribe()
  }

}
