import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IFloorDetail, IRooms } from '../../property/property';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { PropertyService } from '../../shared/services/property.service';
import { PhotoComponent } from '../photo/photo.component';
import { AddRoomComponent } from './add-rooms/single/add-room.component';
import { BrowserStorageService } from '../../shared/services/storage.service';
import { DeleteFloorComponent } from '../floor/delete-floor/delete-floor.component';
import { DeleteRoomComponent } from './delete-room/delete-room.component';
import { FloorNamesPipe } from '../../shared/master-data/pipes/floor-names.pipe';

@Component({
  selector: 'app-rooms',
  standalone: true,
  // providers: [
  //   provideMomentDateAdapter(),
  // ],
  imports: [CommonModule,

    RouterLink,
    RouterOutlet,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    PhotoComponent,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    ConfirmDialogComponent,
    HttpClientModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AddRoomComponent,
    FloorNamesPipe],
  templateUrl: './rooms.component.html',
  styleUrl: './rooms.component.scss'
})


export class RoomsComponent implements OnInit {


  public propertyName!: string | null;
  public propertyId!: number;
  public floorId!: number;
  public floor_name_id!: number;
  private confirmAction = false;
  public floorDetails!: IFloorDetail[];
  public rooms: IRooms[] = [];

  constructor(public dialog: MatDialog, private browserStorageService: BrowserStorageService, private readonly translate: TranslateService, private propertyService: PropertyService, private readonly route: ActivatedRoute) {

    const propertyId = this.route.snapshot.paramMap.get('propertyId');
    const floorId = this.route.snapshot.paramMap.get('floorId');
    const floor_name_id = this.route.snapshot.paramMap.get('floor_name_id');


    // Check if both propertyId and floorId available. 
    if (propertyId && floorId) {
      this.floorId = +floorId;
      this.propertyId = +propertyId;
      this.floor_name_id = Number(floor_name_id);
      this.getRoomDetails();
    } else {
      // Todo: Dispaly error message
      console.log('Error: Either propertyId or Floor Id is missing');
    }


  }

  getRoomDetails() {

    this.propertyService.getAllRoomDetails(this.propertyId, this.floorId).subscribe(result => {
      //console.log(result.data);
      this.rooms = result.data;
      console.log("room", this.rooms)
    });

  }

  ngOnInit() {
    //this.getFloorDetails();
    this.propertyName = this.browserStorageService.get('propertyName');


  }

  /**
   * service for get all rooms for floor
   * @param floor_id 
   */

  getAllRoomsForFloor(floor_id: number) {
    console.log("floor", floor_id)
    // Reset array value
    this.propertyService.getAllRoomsForFloor(floor_id).subscribe(result => {
      console.log(result.data);
      //this.roomDetails = result.floorDetails;

    });

  }

  public deleteRoomDialogBox() {

    let title = '';

    const dialogRef = this.dialog.open(DeleteRoomComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        propertyId: this.propertyId,
        roomDetails: this.rooms,
        title,

      }
    });


  }

}









