<div class="jk-pl-container">
    <div class="jk-pl-loader" *ngIf="showSpinner"> Refreshing the Photo list <app-spinner></app-spinner></div>
    <div class="jk-pl-sub-container">
        <div *ngFor="let photo of this.photos; index as i; " class="jk-card-container">
            <mat-card class="jk-pl-card" [ngClass]="{'jk-pl-primary-photo' : photo.primary_photo}">
                <mat-card-header>
                    
                    @if(photo.bed_type_id && photo.category_id){
                    <mat-card-title>Bed Type :{{photo.bed_type_id | bedType}} </mat-card-title>
                    <mat-card-title>Category :{{photo.category_id | category}} </mat-card-title>
                    }@else{
                        <mat-card-title>Property Photo </mat-card-title>
                        <mat-card-title>&nbsp;</mat-card-title>
                    }
                    <!--<mat-card-subtitle>Software Engineer at Infosys</mat-card-subtitle> -->
                </mat-card-header>

                <!-- This is temporary until all the images path will be changed to yyyy/mm/dd -->
                <!-- <img class="jk-img-old"  mat-card-image src="{{thumbnailUrl + photo.name + '.' + photo.ext}}" alt="My Photo"> -->
                <div class="jk-pl-img-container">
                    <img mat-card-image src="{{thumbnailUrl + photo.path + '/' + photo.name + '.' + photo.ext}}"
                        alt="My Photo">
                </div>
                <mat-card-content>
                    {{photo.taken_date}}
                    <p>
                        {{photo.comments}}
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="!photo.primary_photo" mat-raised-button color="primary"
                        (click)="getUserPrimaryPhotoConfirmation(photo.photo_id)">{{'USER.SET_PRIMARY_PHOTO' | translate
                        }}</button>
                    <button *ngIf="!photo.primary_photo" mat-raised-button
                        (click)="getUserDeleteConfirmation(photo.photo_id)">{{'DIALOG_DELETE_BTN' | translate
                        }}</button>
                    <div class="jk-pl-primary-photo-text" *ngIf="photo.primary_photo" mat-button>{{ 'USER.PRIMARY_PHOTO'
                        |
                        translate }}</div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>