
<div class="jk-property-name">
  <h1>{{propertyName}}</h1>
</div>


<h2>Manage Property Photos</h2>
<div class="jk-photo-selection">


  <mat-radio-group [(ngModel)]="property_room">
    <mat-radio-button value="property">Property</mat-radio-button>
    <mat-radio-button value="room">Room</mat-radio-button>
  </mat-radio-group>



  <!-- <span>Property</span><mat-slide-toggle [(ngModel)]="photoSelection">Room</mat-slide-toggle> -->

</div>
@if(property_room == "room"){
<div class="jk-bed-category-form-field">
  <mat-form-field>
    <mat-label>{{'ADMIN.BED_TYPE' | translate}}</mat-label>
    <mat-select [(ngModel)]="bed_type_id" required>
      @for (bed_type of bed_types; track bed_type.bed_type_id) {
      <mat-option [value]="bed_type.bed_type_id">{{bed_type.bed_type}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'ADMIN.CATEGORY' | translate}}</mat-label>
    <mat-select [(ngModel)]="category_id" required>
      @for (category of categories; track category.category_id) {
      <mat-option [value]="category.category_id">{{category.category}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

</div>
}

<div class="jk-ph-upload" [ngClass]="screenInfo.cssClass">

  <input type="file" class="jk-file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload>

  <div class="file-upload">

    {{fileName || "Please click the icon to choose a photo."}}

    <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
      <mat-icon>attach_file</mat-icon>

    </button>

  </div>

  <div class="jk-ph-upload-progress">
@if(uploadProgress){
    <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" >

    </mat-progress-bar>

    <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
  }
  </div>

</div>

<app-photo-list [refreshPhotoList]="refreshPhotoList"></app-photo-list>