<div class="jk-property-name">
    <h1>{{propertyName}}</h1>
</div>



@if(showRoomDetails ){
<div class="jk-floor-title">
    <h1>{{'ADMIN.MANAGE_ROOM' | translate}} </h1>
    <h2>{{'ADMIN.FLOOR' | translate}} {{propertyRooms.floor_id }}</h2>
</div>

<div class="jk-container">


    <form [formGroup]="roomsFormGroup">

        <mat-form-field>
            <mat-label>{{'ADMIN.ROOM_NO' | translate}}</mat-label>
            <input type="text" matInput formControlName="room_number" min="1">
            @if (roomsFormGroup.controls['room_number']?.hasError('required')) {
            <mat-error>Room No. is required</mat-error>
            }

        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'ADMIN.BED_TYPE' | translate}}</mat-label>
            <mat-select formControlName="bed_type_id">
                @for (bed_type of bed_types; track bed_type.bed_type_id) {
                <mat-option [value]="bed_type.bed_type_id">{{bed_type.bed_type}}</mat-option>
                }
            </mat-select>
            @if (roomsFormGroup.controls['bed_type_id']?.hasError('required')) {
            <mat-error>Bed Type is required</mat-error>
            }
        </mat-form-field>


        <!-- <mat-form-field>
            <mat-label>{{'ADMIN.CATEGORY' | translate}}</mat-label>
            <mat-select formControlName="category_id">
                @for (category of categories; track category.category_id) {
                <mat-option [value]="category.category_id">{{category.category}}</mat-option>
                }
            </mat-select>
            @if (roomsFormGroup.controls['category_id']?.hasError('required')) {
            <mat-error>Category is required</mat-error>
            }
        </mat-form-field> -->


        <mat-form-field>
            <mat-label>{{'ADMIN.NO_OF_OCCUPANCY' | translate}}</mat-label>
            <input type="number" matInput formControlName="no_of_occupancy" min="1">
            @if (roomsFormGroup.controls['no_of_occupancy']?.hasError('required')) {
            <mat-error>No. of Occupancy is required</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'ADMIN.ADDITIONAL_OCCUPANCY' | translate}}</mat-label>
            <input type="number" matInput formControlName="no_of_extra_bed" min="0">
            @if (roomsFormGroup.controls['no_of_extra_bed']?.hasError('required')) {
            <mat-error>Additional Occupancy is required</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'ADMIN.PRICE_FOR_EXTRA_BED' | translate}}</mat-label>
            <input type="number" matInput formControlName="price_for_extra_bed">

        </mat-form-field>

        <!-- <div> -->

            <mat-form-field>
                <mat-label>{{'ADMIN.WASH_ROOM_TYPE' | translate}}</mat-label>
                <mat-select formControlName="washroom_type_id">
                    @for (washroom_type of washroom_types; track washroom_type.washroom_type_id) {
                    <mat-option [value]="washroom_type.washroom_type_id">{{washroom_type.washroom_type}}</mat-option>
                    }
                </mat-select>
                @if (roomsFormGroup.controls['washroom_type']?.hasError('required')) {
                <mat-error>Wash Room Type is required</mat-error>
                }
            </mat-form-field>


            <mat-slide-toggle formControlName="water_heater" color="primary">{{'ADMIN.WATER_HEATER'
                | translate}}</mat-slide-toggle>
            <mat-slide-toggle formControlName="ac" color="primary">{{'ADMIN.AC' |
                translate}}</mat-slide-toggle>
            <mat-slide-toggle formControlName="tv" color="primary">{{'ADMIN.TV' |
                translate}}</mat-slide-toggle>


            <div class="jk-remove">
                <div class="jk-day">

                    <mat-label>{{'ADMIN.DAYS_AVAILABLE_TO_CRMS' | translate}}</mat-label>
                    <div> <mat-checkbox formControlName="select_all" [checked]="true"
                            (click)="checkAll()">{{'ADMIN.SELECT_ALL' | translate}}</mat-checkbox>
                    </div>
                    <section>
                        <mat-checkbox formControlName="sun">{{'ADMIN.SUNDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="mon">{{'ADMIN.MONDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="tue">{{'ADMIN.TUESDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="wed">{{'ADMIN.WEDNESDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="thu">{{'ADMIN.THURSDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="fri">{{'ADMIN.FRIDAY' |
                            translate}}</mat-checkbox>
                        <mat-checkbox formControlName="sat">{{'ADMIN.SATURDAY' |
                            translate}}</mat-checkbox>

                    </section>

                </div>
            </div>
        <!-- </div> -->

        <div class="jk-price-container">

            <h2>{{'ADMIN.PRICE' | translate}}</h2>

            <div formArrayName="room_prices">

                <div *ngFor="let formArrayGroup of getPriceFormArray().controls;let i=index">

                    <div class="jk-price" [formGroupName]="i">
                        <mat-form-field color="primary">
                            <mat-label>{{'ADMIN.CHOOSE_DATE_FROM' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="from_date"
                                placeholder="DD/MM/YYYY" maxlength="10">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            @if
                            (getPriceFormArray().controls[i].get('from_date')?.hasError('required'))
                            {
                            <mat-error>From Date is required</mat-error>
                            }
                        </mat-form-field>

                        <mat-form-field color="primary">
                            <mat-label>{{'ADMIN.CHOOSE_DATE_TO' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="to_date" placeholder="DD/MM/YYYY"
                                (dateChange)="resetDateForNextRow($event, i)">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 color="primary"></mat-datepicker>
                            @if
                            (getPriceFormArray().controls[i].get('to_date')?.hasError('required'))
                            {
                            <mat-error>To Date is required</mat-error>
                            }
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'ADMIN.ACTUAL_PRICE_NIGHT' | translate}}</mat-label>
                            <input type="number" matInput formControlName="price" min="0">
                            @if
                            (getPriceFormArray().controls[i].get('price')?.hasError('required'))
                            {
                            <mat-error>Actual Tarriff / Night is required</mat-error>
                            }
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{'ADMIN.DISCOUNTED_PRICE' | translate}} </mat-label>
                            <input type="number" matInput formControlName="discounted_price" min="0">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{'ADMIN.COMMISSION_ON_TARRIFF' | translate}}</mat-label>
                            <input type="number" matInput formControlName="commission_percent" min="0">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{'ADMIN.COMMISSION_RS' | translate}} </mat-label>
                            <input type="number" matInput formControlName="commission_rupees" min="0">
                        </mat-form-field>

                        <div class="jk-delete-icon">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="delete"
                                (click)=" removePrice1(i)" color="warn"></mat-icon>
                        </div>
                    </div>


                    <!-- <div class="jk-price-buttons">
                       
                        <button mat-raised-button color="warn" (click)=" removePrice1(i)">{{'ADMIN.REMOVE_PRICE' |
                            translate}}</button>
                    </div> -->
                </div>
                <div class="jk-price-buttons">
                    <button mat-raised-button color="primary" (click)="addPriceRow(0)">{{'ADMIN.ADD_PRICE' |
                        translate}}</button>
                </div>
            </div>
        </div>

        <div class="jk-footer-buttons">

            <button mat-raised-button color="primary" type="submit" [disabled]="!roomsFormGroup.valid"
                (click)="onSubmit()">{{'ADMIN.SUBMIT'
                | translate}}</button>
        </div>
    </form>
</div>
}@else{
<h1>Loading...</h1>
<app-spinner></app-spinner>
}